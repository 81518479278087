// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogpost-js": () => import("/opt/build/repo/src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gigs-js": () => import("/opt/build/repo/src/pages/gigs.js" /* webpackChunkName: "component---src-pages-gigs-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("/opt/build/repo/src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

